import { Component } from 'react';
import './App.css';

import mp3 from './leoseilerweb.mp3';

//import images
import leo from './pictures/leo.jpg'
import personnalite from './pictures/personnalite.png'
import gemleo from './pictures/gemleo.png'
import leohacker from './pictures/leohacker.png'
import sound from './pictures/sound.gif'
import leoniger from './pictures/leoniger.png'
import leoescalade from './pictures/leoescalade.png'
import leomange from './pictures/leomange.png'
import leovictime from './pictures/leovictime.png'

class App extends Component {

  render() {
    return (
    <div className="container-fluid App">
        <header className="row App-header">
            <div className="text-center">
              <p><img className="leo" src={leo} /><span className="m-4">WHO'S LEO SEILER</span><img className="leo" src={leo} /></p>
            </div>
        </header>

        <div className="connaitre row text-center">
            <p className="titrecadre"><span>Connaître Léo</span></p>
            <div className="col-md-4 p-1">
              <p>Léo n'est pas lion mais gémeaux (bouh)<br/><i><small>Pourquoi il s'appelle Léo alors ?</small></i></p>
              <img id="gemleo" src={gemleo} className="col-md-12" />
            </div>
            <div className="col-md-4 p-1">
              <p>La personnalité de Léo, ou comment connaître Léo<br/> à partir de son signe astro<br/></p>
              <img id="personnalite" src={personnalite} className="col-md-12" />
            </div>
            <div className="col-md-4 p-1">
              <p>Le métier de Léo</p>
              <div className="row p-4">
                  <div className="col-md-6">
                    <img id="leohacker" src={leohacker} className="col-md-12" />
                  </div>
                  <div className="col-md-6">
                    Leo est un "hacker", il fait partie de la police des ordinateurs, il se bat contre les méchants des ordinateurs (du moins il fait croire).
                    Enfin, il a quand même été le coup de coeur du jury lors du Cyber West Challenge à St Malo (merci pour la photo).
                  </div>
              </div>
            </div>
        </div>

        <div className="passions row text-center">
            <p className="titrecadre"><span>Les passions de Léo</span></p>
            <div className="col-md-3 p-1"><p>Niger</p><img id="leoniger" src={leoniger} /><p className="mt-2">Niger est une des passions de Léo, son rêve serait de devenir maître nigeur. Mais avant ça il faudra qu'il apprenne à ne plus tomber des marches de la petite piscine ;) ! Puis il s'est fait battre par Elodie lors d'une course improvisée donc bon, l'espoir fait vivre.</p></div>
            <div className="col-md-3 p-1"><p>Escalader</p><img id="leoescalade" src={leoescalade} /><p className="mt-2">Un de ses sports préférés, dans lequel il trouvera toujours une bonne excuse pour ses ratés : "j'ai les mains qui glissent", "j'ai des courbatures", "la prise est trop loin", "je suis fatigué aujourd'hui", "c'est pas mon jour".</p></div>
            <div className="col-md-3 p-1"><p>Se victimiser</p><img id="leovictime" src={leovictime} /><p className="mt-2">Grosse victime qu'il est, pleinement conscient et jouant de la situation, il s'est très vite fait démasquer. Mais quelques collègues sympas rentrent dans son jeu pour lui faire plaisir ou par pitié peut être... on ne le saura jamais.</p></div>
            <div className="col-md-3 p-1"><p>Manger healthy</p><img id="leomange" src={leomange} /><p className="mt-2">Souvent avec une petite salade diversifiée et colorée au bureau le midi, c'est sûr,<br/> il a un compte instagram "healthy food"<br/> caché quelque part...</p></div>
        </div>

        <div className="bonus row">
            <div className="col-md-12 text-center">
                <div className="row">
                <p className="titrecadre"><span>BONUS : Léo's Song</span></p>
                  <div className="col-md-4 sound"><img src={sound} /></div>
                  <div className="col-md-4"><audio className="mt-4" controls src={mp3}></audio></div>
                  <div className="col-md-4 sound"><img src={sound} /></div>
                </div>
              </div>
          </div>
    </div>
  );
  }
  
}

export default App;
